<div class="wrapper">
  <div class="authentication-forgot d-flex align-items-center justify-content-center">
    <div class="card forgot-box">
      <div class="card-body">
        <ng-container *ngIf="!isRequestSent; else requestSent">
          <div class="p-4 rounded  border">
            <div class="card-title text-center"><i class="bx bxs-lock font-50" style="color: #607C8A;"></i>
              <h5 class="mb-5 mt-2" style="color: #e4c6ae;">Forgot Password?</h5>
            </div>
            <p class="text-muted">Enter your registered Email Address to reset the password</p>
            <form [formGroup]="recoveryForm">
              <div class="my-4">
                <label class="form-label">Email Address</label>
                <input type="text" class="form-control form-control-lg" formControlName="emailAddress" placeholder="example@user.com" />
              </div>
              <div class="d-grid gap-2">
                <button class="btn btn-lg" type="button" (click)="recover()" [disabled]="!recoveryForm.valid" style="background-color: #e4c6ae;">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Send</button>
                <a href="javascript:;" (click)="onSignIn()" class="btn btn-light btn-lg"><i class='bx bx-arrow-back me-1'></i>Back to Login</a>
              </div>
            </form>
          </div>
        </ng-container>
        <ng-template #requestSent>
          <div class="header">We sent you an email with recovery instructions.</div>
          <a href="javascript:;" (click)="onSignIn()" class="btn btn-light btn-lg"><i class='bx bx-arrow-back me-1'></i>Back to
            Login</a>
        </ng-template>
      </div>
    </div>
  </div>
</div>

