<div class="row">
	<div class="col-sm-4 mx-auto">
		<div class="card border-top border-0 border-4 border-grey">
			<div class="card-body p-4">
				<div class="card-title d-flex align-items-center justify-content-center" >
          <div>
            <img src="assets/images/HWWKLogo.jpg" class="logo-icon" alt="HWWK Logo">
          </div>
				</div>
				<hr>
				<form class="row g-3" [formGroup]="signupForm">
					<div class="col-sm-6">
						<label for="inputLastName1" class="form-label">First Name</label>
						<div class="input-group"> <span class="input-group-text bg-transparent"><i class='bx bxs-user'></i></span>
							<input type="text" class="form-control border-start-0" formControlName="firstName" id="inputLastName1" placeholder="First Name" />
						</div>
            <div *ngIf="f.firstName.touched && f.firstName.invalid" class="text-danger">
              <div *ngIf="f.firstName.errors?.required">First Name is required.</div>
            </div>
					</div>
					<div class="col-sm-6">
						<label for="inputLastName2" class="form-label">Last Name</label>
						<div class="input-group"> <span class="input-group-text bg-transparent"><i class='bx bxs-user'></i></span>
							<input type="text" class="form-control border-start-0" formControlName="lastName" id="inputLastName2" placeholder="Last Name" />
						</div>
            <div *ngIf="f.lastName.touched && f.lastName.invalid" class="text-danger">
              <div *ngIf="f.lastName.errors?.required">Last Name is Required</div>
            </div>
					</div>
					<div class="col-12">
						<label for="inputPhoneNo" class="form-label">Middle Name</label>
						<div class="input-group"> <span class="input-group-text bg-transparent"><i class='bx bxs-user' ></i></span>
							<input type="text" class="form-control border-start-0" formControlName="middleName" id="inputMiddleName" placeholder="Middle Name" />
						</div>
					</div>
          <div class="col-sm-6">
						<label for="inputPhoneNo" class="form-label">Phone No</label>
						<div class="input-group"> <span class="input-group-text bg-transparent"><i class='bx bxs-microphone' ></i></span>
							<input type="text" class="form-control border-start-0" formControlName="phoneNo" id="inputPhoneNo" placeholder="Phone No" />
						</div>
            <div *ngIf="f.phoneNo.touched && f.phoneNo.invalid" class="text-danger">
              <div *ngIf="f.phoneNo.errors?.required">Mobile Number is required.</div>
              Check Minimum Input phone number is 10 and Maximum Number is 13
            </div>
					</div>
					<div class="col-sm-6">
						<label for="inputEmailAddress" class="form-label">Email Address</label>
						<div class="input-group"> <span class="input-group-text bg-transparent"><i class='bx bxs-message' ></i></span>
							<input type="email" class="form-control border-start-0" formControlName="emailAddress" id="inputEmailAddress" placeholder="Email Address" />
						</div>
            <div *ngIf="f.emailAddress.touched && f.emailAddress.invalid" class="text-danger">
              <div *ngIf="f.emailAddress.errors?.required">Email Adddress is required.</div>
              Invalid Email Address
            </div>
					</div>
					<div class="col-sm-6">
						<label for="inputChoosePassword" class="form-label">Choose Password</label>
						<div class="input-group"> <span class="input-group-text bg-transparent"><i class='bx bxs-lock-open' ></i></span>
							<input type="password" class="form-control border-start-0" formControlName="password" id="inputChoosePassword" placeholder="Choose Password" />
						</div>
            <div *ngIf="f.password.touched && f.password.invalid" class="text-danger">
              <div *ngIf="f.password.errors?.required">Password is required.</div>
              Input 8 Character Password
            </div>
					</div>
					<div class="col-sm-6">
						<label for="inputConfirmPassword" class="form-label">Confirm Password</label>
						<div class="input-group"> <span class="input-group-text bg-transparent"><i class='bx bxs-lock' ></i></span>
							<input type="password" class="form-control border-start-0" formControlName="passwordRepeat" id="inputConfirmPassword" placeholder="Confirm Password" />
						</div>
            <div *ngIf="f.passwordRepeat.touched && f.passwordRepeat.invalid" class="text-danger">
              <div *ngIf="f.passwordRepeat?.errors?.required">Password is required.</div>
              <div *ngIf="f.passwordRepeat?.errors?.confirmedValidator">Password and Confirm Password must be match.</div>
            </div>
					</div>
          <div class="col-12">
            <div class="d-grid gap-2">
              <button type="button" [disabled]="loading" (click)="signup()" [disabled]="!signupForm.valid" class="btn btn-md pt-2" style="background-color: #e4c6ae;">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Register
              </button>
            </div>
          </div>
				</form>
        <hr />
        <div class="col-12 text-center">
          <a routerLink="/sign-in" class="mb-0" style="color: #e4c6ae;">Already Sign Up? Login</a>
        </div>
        <hr />
			</div>
		</div>
</div>
</div>
